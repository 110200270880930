import React from 'react';
import s from './Coupon.scss';
import {TagIcon} from '../Icons/Tag';
import {CouponDataHook} from './CouponOld/CouponOld';
import settingsParams from '../../../settingsParams';
import {useSettingsWithDefaults} from '../../../../../hooks/useSettingsWithDefaults';
import cx from 'classnames';

export interface IEnterACouponProps {
  onToggle: () => void;
  disabled?: boolean;
}
export const EnterACouponButton: React.FC<IEnterACouponProps> = ({onToggle, disabled}) => {
  const {getSettingsWithDefaults} = useSettingsWithDefaults();

  return (
    <button
      data-hook={CouponDataHook.CouponShowButton}
      className={cx(s.textButton, disabled ? s.disabled : null)}
      type="button"
      onClick={onToggle}
      disabled={disabled}>
      <TagIcon />
      <span id="coupon-label" data-hook={CouponDataHook.CouponShowButtonText} className={s.labelWithIcon}>
        {getSettingsWithDefaults(settingsParams.CART_ADD_PROMO_CODE_LINK)}
      </span>
    </button>
  );
};
