import React, {useState} from 'react';
import {useEnvironment, useTranslation} from '@wix/yoshi-flow-editor';
import s from '../Coupon.scss';
import {classes as stylableClasses} from '../Coupon.st.css';
import {useControllerProps} from '../../../../../../domain/controllers/ControllerContext';
import {ApplyOffer, ErrorViewType} from '@wix/wixstores-client-common-components';
import {getTooltipErrorMessageOptions} from '../Coupon.helper';
import {promisify} from '../../../../../../domain/lib/promisify';
import {Tooltip} from 'wix-ui-tpa';
import {EnterACouponButton} from '../EnterACouponButton';
import {ProductType} from '@wix/wixstores-client-core';

export enum CouponDataHook {
  CouponShowButton = 'Coupon.ShowButton',
  CouponShowButtonText = 'Coupon.ShowButtonText',
}

export const CouponOld = () => {
  const {t} = useTranslation();
  const {isRTL} = useEnvironment();
  const {
    cartModel,
    estimatedTotals,
    applyCoupon,
    removeCoupon,
    couponError,
    sendToggleCouponBi,
    isGiftCardMixedCartEnabled,
  } = useControllerProps().cartStore;
  const appliedCoupon = estimatedTotals?.appliedCoupon || cartModel.appliedCoupon;
  const initialCouponCode: string = appliedCoupon?.code || '';
  const [couponCode, setCouponCode] = useState<string>(initialCouponCode);
  const [toggleInput, setToggleInput] = useState<null | boolean>(null);

  const isCouponApplied = !!appliedCoupon;

  const getError = () =>
    couponError ? t(`cart.errors.coupons.${couponError.code}`, getTooltipErrorMessageOptions(couponError)) : '';

  const handleApplyCoupon = (code: string) => {
    return applyCoupon(code);
  };

  const handleRemoveCoupon = () => {
    setCouponCode('');
    return removeCoupon();
  };

  const onToggle = () => {
    const updatedToggleInput = !toggleInput;
    setToggleInput(updatedToggleInput);

    if (updatedToggleInput) {
      sendToggleCouponBi();
    }
  };

  const getToggler = () => {
    return isGiftCardMixedCartEnabled && cartModel.lineItemsTypes.includes(ProductType.GIFT_CARD) ? (
      <Tooltip content={t('cart.errors.coupons.ERROR_CANT_PURCHASE_GC_MESSAGE')} minWidth={220}>
        <EnterACouponButton onToggle={onToggle} disabled />
      </Tooltip>
    ) : (
      <EnterACouponButton onToggle={onToggle} />
    );
  };

  return (
    <ApplyOffer
      isRTL={isRTL}
      shouldAnimate={false}
      className={s.coupon}
      stylableClasses={stylableClasses}
      value={couponCode}
      isApplied={isCouponApplied}
      successMessage={t('cart.coupon_applied')}
      errorMessage={getError()}
      errorViewType={ErrorViewType.TOOLTIP}
      isCollapsible={true}
      labelId={'coupon-label'}
      fullWidthApplyButton={false}
      placeholder={t('cart.coupon_input_placeholder')}
      applyButtonText={t('cart.coupon_apply_button_label')}
      removeButtonText={t('cart.remove_button')}
      toggler={getToggler()}
      toggleInput={toggleInput}
      onApply={promisify(handleApplyCoupon)}
      onClear={promisify(handleRemoveCoupon)}
    />
  );
};
